<template>
  <div class="child-floor">
    <en-table-layout :tableData="tableData.data" :loading="loading">
      <template slot="toolbar">
        <el-form-item class="col-auto" style="padding: 4px 12px 4px 12px;">
          <el-input v-model.trim="params.navigation_name" style="width:280px;margin-right:10px;" placeholder="请输入楼层名称"
            clearable>
          </el-input>
          <el-button size="small" type="primary" @click="GET_SiteMenuList">
            搜索
          </el-button>
          <el-button size="small" type="primary" @click="handleAddSiteMenu">
            添加子楼层
          </el-button>
        </el-form-item>
      </template>

      <template slot="table-columns">
        <el-table-column label="排序" fixed="left">
          <template slot-scope="scope">
            {{ (scope.$index + 1) + ((tableData.page_no - 1) * tableData.page_size) }}
          </template>
        </el-table-column>
        <el-table-column prop="navigation_name" label="楼层名称" min-width="200" fixed="left" />
        <el-table-column prop="create_date" label="创建时间" min-width="200">
          <template slot-scope="{row}">
            <span>{{ row.create_date | unixToDate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="update_date" label="更新时间" min-width="200">
          <template slot-scope="{row}">
            <span>{{ row.update_date | unixToDate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="count" label="商品数量" min-width="200" />
        <!-- <el-table-column label="链接地址" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.url }}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="是否为顶部导航菜单" width="150">
          <template slot-scope="scope">
            <!-- {{scope.row.display}} -->
            <span class="disMask" @click="changeDisplay(scope.row.navigation_id, scope.row.display ? 1 : 0)">
              <el-switch v-model="scope.row.display" :active-value="1" :inactive-value="0"></el-switch>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="370" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleSortSiteMenu(scope.$index, scope.row, 'up')">
              上移
            </el-button>
            <el-button size="mini" @click="handleSortSiteMenu(scope.$index, scope.row, 'down')">
              下移
            </el-button>
            <el-button size="mini" type="primary" @click="handleEditSiteMenu(scope.$index, scope.row)">
              编辑
            </el-button>
            <el-button size="mini" type="primary" @click="handleEditChildPage(scope.$index, scope.row)">
              页面编辑
            </el-button>
            <el-button size="mini" type="danger" @click="handleDeleteSiteMenu(scope.$index, scope.row)">
              删除
            </el-button>

          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="tableData.page_size" :total="tableData.data_total">
      </el-pagination>
    </en-table-layout>

    <x-dialog :proxy="editDialog">
      <el-form :model="siteMenuForm" :rules="siteMenuRules" ref="siteMenuForm" label-width="110px">
        <el-form-item label="子楼层名称" prop="navigation_name" style="margin-top: 22px">
          <el-input placeholder="请输入子楼层名称" v-model="siteMenuForm.navigation_name" clearable :maxlength="20"></el-input>
        </el-form-item>
        <el-form-item v-if="openUncleCake" label="子楼层定位" prop="location" style="margin-top: 22px">
          <el-radio-group v-model="siteMenuForm.location">
            <el-radio :label="1">有定位</el-radio>
            <el-radio :label="0">无定位</el-radio>
          </el-radio-group>
          <p style="color: red;margin: 0;">建议蛋糕类商品建议选择有定位</p>
        </el-form-item>
        <!-- <el-form-item label="子楼层链接" prop="url">
          <el-input placeholder="请输入子楼层链接（例：jingdong）" v-model="siteMenuForm.url" clearable :maxlength="225"
            :disabled="editStatus"></el-input>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="submitSiteMenuForm('siteMenuForm')">确 定</el-button>
      </div>
    </x-dialog>
  </div>
</template>

<script>
import * as API_SiteMenu from '@/api/siteMenu'
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';
import XDialog from '@/components/x-dialog/x-dialog';
import { $xDialog } from '@/components/x-dialog/dialog.proxy';
import * as API_Floor from '@/api/floor';
export default {
  name: 'mobile-site-menu',
  components: {
    XDialog,
    EnTableLayout
  },
  data () {
    let validateUrl = (rule, value, callback) => {
      let validate = /^\S.*$/gi.test(value)
      if (value === undefined || value === '') callback(new Error('请输入子楼层链接'))
      else if (!validate) callback(new Error('子楼层链接不能以空格开始'))
      else if (value.split('/').length > 1) callback(new Error('子楼层链接不能包含 /'))
      else callback()
    };
    return {
      openUncleCake:false,//是否显示定位
      editDialog: $xDialog.create({
        width: '400px',
        beforeConfirm: () => this.submitSiteMenuForm('siteMenuForm'),
      }),

      /** 列表loading状态 */
      loading: false,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        client_type: 'MOBILE',
        navigation_name: ""
      },

      /** 列表数据 */
      tableData: {},
      editStatus: false,

      /** 添加、编辑子级楼层 dialog */
      dialogVisible: false,

      /** 添加、编辑子级楼层 表单 */
      siteMenuForm: {
        navigation_name: '',
        location: 0,
        url: 'admin/',
        image: ''
      },

      /** 添加、编辑子级楼层 表单规则 */
      siteMenuRules: {
        navigation_name: [this.MixinRequired('请输入子楼层名称！')],
        url: [{ validator: validateUrl, trigger: 'blur' }],
        image: [this.MixinRequired('请选择导航图片！')],
        location: [this.MixinRequired('请选择定位！')]
      }
    }
  },
  mounted () {
    this.GET_SiteMenuList();
  },
  methods: {
    getNavs(){
      API_Floor.getNav().then(res => {
        if(res.some(item=>item.navigation_name=='蛋糕')){
          this.openUncleCake=true
        }
      })
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size
      this.GET_SiteMenuList()
    },
    changeDisplay (id, display) {
      API_SiteMenu.changeDisplay({
        id,
        display
      }).then(res => {
        this.GET_SiteMenuList()
      })
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page
      this.GET_SiteMenuList()
    },

    /** 添加子级楼层 */
    handleAddSiteMenu () {
      this.editStatus = false;
      this.siteMenuForm = {
        location: 0
      };
      this.editDialog.display({ title: '添加子楼层' });
    },

    /** 编辑子级楼层 */
    handleEditSiteMenu (index, row) {
      this.editStatus = true;
      this.siteMenuForm = this.MixinClone(row);
      this.editDialog.display({ title: '编辑子楼层' });
    },

    /** 编辑子级楼层页面 */
    handleEditChildPage (index, row) {
      this.$router.push({
        name: 'floorWidthParams',
        params: {
          type: 'child',
          id: row.navigation_id
        }
      });
    },

    /** 删除子级楼层 */
    handleDeleteSiteMenu (index, row) {
      this.$confirm('确定要删除这个子楼层吗？', '提示', { type: 'warning' }).then(() => {
        API_SiteMenu.deleteSiteMenu(row.navigation_id).then(response => {
          this.$message.success('删除成功！')
          this.GET_SiteMenuList()
        })
      }).catch(() => {
      })
    },

    /** 添加、编辑子级楼层 提交表单 */
    submitSiteMenuForm (formName) {
      return new Promise(resolve => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const { navigation_id } = this.siteMenuForm
            if (navigation_id) {
              API_SiteMenu.editSiteMenu(navigation_id, this.siteMenuForm).then(response => {
                this.$message.success('保存成功！');
                this.MixinSetTableData(this.tableData, 'navigation_id', navigation_id, response);
                resolve(true);
              }).catch(() => resolve(false));
            } else {
              API_SiteMenu.addSiteMenu(this.params.client_type, this.siteMenuForm).then(response => {
                this.$message.success('添加成功！');
                this.GET_SiteMenuList();
                resolve(true);
              }).catch(() => resolve(false));
            }
          } else {
            this.$message.error('表单填写有误，请检查！');
            resolve(false);
          }
        })
      });
    },

    /** 导航排序 */
    handleSortSiteMenu (index, row, sort_type) {
      API_SiteMenu.sortSiteMenu(row.navigation_id, sort_type).then(response => {
        this.GET_SiteMenuList()
        this.$message.success('操作成功！')
      })
    },

    /** 获取子级楼层列表 */
    GET_SiteMenuList () {
      this.loading = true
      API_SiteMenu.getSiteMenuList(this.params).then(response => {
        this.loading = false
        this.tableData = response
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.disMask {
  position: relative;
  cursor: pointer;
}

.disMask:before {
  display: block;
  content: ' ';
  position: absolute;
  width: 100%;
  height: 26px;
  left: 0;
  top: -6px;
  z-index: 1;
}
</style>
